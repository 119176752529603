import { IVirtualView } from 'kach-clients'
import React, { useCallback, useContext, useState } from 'react'
import { GiConsoleController } from 'react-icons/gi'
import reactSelect from 'react-select'
import { IDynamicObject } from '../components/InvestigationLine'
import { IColumnsForm, ITableColumnsController } from '../hooks/useTableColumns'
import { UseFormGetValues } from 'react-hook-form'

export interface ITableContext {
  params: IDynamicObject
  setParams: (params: IDynamicObject) => void
  virtualView?: IVirtualView
  setVirtualView: (virtualView: IVirtualView) => void
  cols: ITableColumnsController
}

export const useTableContext = () => useContext(TableContext)!

export const TableContext = React.createContext<ITableContext | null>(null)

export interface IVirtualViewRefFilter {
  entityId?: number
  entityData?: any
}

export const TableContextProvider = (props: {
  children: React.ReactNode
  virtualView?: IVirtualView
  tableColumns?: ITableColumnsController
}) => {
  const [params, setParams] = useState<IDynamicObject>()

  const [virtualView, setVirtualView] = useState<IVirtualView | undefined>(
    props.virtualView,
  )

  return (
    <TableContext.Provider
      value={{
        params,
        setParams,
        virtualView,
        setVirtualView,
        cols: props?.tableColumns,
      }}
    >
      {props.children}
    </TableContext.Provider>
  )
}
